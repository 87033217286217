import { equivalenceRoutes } from '../global/Constants/equivalence-routes';
import { IDataLogin } from '../services/auth/interfaces/login.interface';
import { decryptData } from './decrypt-data';

export const getRouteInit = () => {
	const userAuth = localStorage.getItem('dataUser');
	if (userAuth) {
		const decryptDataUser: IDataLogin = decryptData(userAuth);
		if (decryptDataUser.admin) return '/';
		else {
			const routeRedirect = '/dashboard';
			const permissionListFilter = decryptDataUser.capabilityPermission.general.filter((item) => item.includes('list'));
			const equivalenceFilter = equivalenceRoutes.filter((item) =>
				permissionListFilter.includes(item.equivalence + '_list')
			);
			const equivalenceListOrder = equivalenceFilter.sort((a, b) => a.id - b.id);

			if (equivalenceListOrder.length > 0) {
				return equivalenceListOrder[0].route + '/listar';
			} else return routeRedirect;
		}
	}
	return '/';
};
