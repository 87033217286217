export const equivalenceRoutes = [
	{
		id: 0,
		route: 'configuracion/usuario',
		equivalence: 'user_person'
	},
	{
		id: 1,
		route: 'configuracion/puesto-trabajo',
		equivalence: 'work_job'
	},
	{
		id: 2,
		route: 'configuracion/rol',
		equivalence: 'capability_role'
	},
	{
		id: 3,
		route: 'comercial/matricula-productos',
		equivalence: 'content_product'
	},
	{
		id: 4,
		route: 'comercial/matricula-productos/producto-bundle',
		equivalence: 'content_productbundle'
	},
	{
		id: 5,
		route: 'comercial/matricula-productos/producto-unico',
		equivalence: 'content_product'
	},
	{
		id: 6,
		route: 'comercial/mantenedor-familias',
		equivalence: 'taxonomy_category'
	},
	{
		id: 7,
		route: 'comercial/reglas-comerciales/producto',
		equivalence: 'rule_businessproduct'
	},
	{
		id: 8,
		route: 'comercial/reglas-comerciales/contrato',
		equivalence: 'rule_businesscontract'
	},
	{
		id: 9,
		route: 'mapas/camposanto',
		equivalence: 'geographical_cemetery'
	},
	{
		id: 10,
		route: 'mapas/objetos',
		equivalence: 'geographical_object'
	},
	{
		id: 11,
		route: 'mapas/areas',
		equivalence: 'geographical_area'
	},
	{
		id: 12,
		route: 'mapas/gestion',
		equivalence: 'geographical_map_get'
	},
	{
		id: 13,
		route: 'mapas/informacion-estrategica',
		equivalence: 'strategic_information'
	},
	{
		id: 14,
		route: 'configuracion/mantenedor-factores',
		equivalence: 'geographical_factor'
	},
	{
		id: 15,
		route: 'configuracion/mantenedor-precios',
		equivalence: 'contentproduct_preferentialprice'
	},
	{
		id: 16,
		route: 'mapas/gestion-vista-estrategica',
		equivalence: 'geographical_mapstrategic_get'
	},
	{
		//equivalence route management contract with permision
		id: 17,
		route: 'gestion-contratos/contratos',
		equivalence: ''
	},
	{
		//equivalence route management contract with permision
		id: 18,
		route: 'gestion-contratos/contratos/reserva-anticipada',
		equivalence: ''
	}
];

export const equivalenceActions = [
	{
		route: 'listar',
		equivalence: 'list'
	},
	{
		route: 'detalle',
		equivalence: 'show'
	},
	{
		route: 'edicion',
		equivalence: 'update'
	},
	{
		route: 'crear',
		equivalence: 'create'
	}
];
