import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { I_AnimatedIconTrigger } from './animation-icon.interface';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'fe-animated-icon',
	templateUrl: './animated-icon.component.html',
	styleUrls: ['./animated-icon.component.scss'],
	standalone: true,
	imports: [CommonModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AnimatedIconComponent implements OnInit {
	public _name: string;

	@Input() id = '';
	@Input() size: string | number;
	@Input() scale: string | number;
	@Input() trigger: I_AnimatedIconTrigger;
	@Input() colors: string;
	@Input() colorPrimary: string;
	@Input() colorSecondary: string;

	@Input() set name(value: string) {
		this._name = value;
		this.updateClasses();
	}

	public classes!: Array<string>;

	constructor() {
		this._name = 'dxaimgmp';
		this.size = '120';
		this.trigger = 'loop';
		this.colors = 'primary:#567bba';
		this.colorPrimary = '#567bba';
	}

	ngOnInit() {
		this.updateClasses();
	}

	private updateClasses() {
		this.classes = ['animated-icon'];
		if (this.colorPrimary && this.colorSecondary) {
			this.colors = `primary:${this.colorPrimary}`;
		}
	}
}
