<section [id]="id">
	<div class="spinner-custom"></div>

	<div *ngIf="!!message; else generalMessage">
		<span class="spinner-text">{{ message }}</span>
	</div>
	<ng-template #generalMessage>
		<span class="spinner-text"
			>Un momento, por favor.<br />
			Estamos cargando la información que solicitaste.</span
		>
	</ng-template>
</section>
