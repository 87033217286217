import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I_ButtonIconPosition, I_ButtonMode, I_ButtonSize, I_ButtonType } from './button.interface';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { AnimatedIconComponent } from '../animated-icon/animated-icon.component';

@Component({
	selector: 'fe-button',
	templateUrl: 'button.component.html',
	styleUrls: ['button.component.scss'],
	standalone: true,
	imports: [CommonModule, IconComponent, AnimatedIconComponent]
})
export class ButtonComponent implements OnInit {
	@Input() id = '';
	@Input() type: I_ButtonType = 'default';
	@Input() mode: I_ButtonMode = 'primary';
	@Input() size: I_ButtonSize = 'md';
	@Input() disabled = false;
	@Input() disabledWhite = false;
	@Input() text = '';
	@Input() icon = '';
	@Input() iconPosition: I_ButtonIconPosition = 'left';
	@Input() iconSize = 24;
	@Input() loading = false;
	@Output() clicked = new EventEmitter();
	@Input() img = '';
	@Input() minWidth = '107px';
	@Input() isIconMaterial = false;

	public classes!: Array<string>;

	constructor() {}

	ngOnInit() {
		this.classes = [
			'fe-button',
			'fe-button--' + this.type,
			'fe-button__mode-' + this.mode,
			//Only icon
			(this.icon && !this.text) || (this.loading && !this.text) ? 'size-icon-' + this.iconSize : '',
			'color',
			'size-' + this.size
		];
	}

	onClicked() {
		if (!this.disabled) {
			this.clicked.emit();
		}
	}
}
