import { EventEmitter, Injectable } from '@angular/core';
import { IRequestLogin, IRequestMicrosoft, IResponseLogin } from '../interfaces/login.interface';
import { URL_BACKEND } from 'src/app/core/config/url';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private _httpClient: HttpClient) {}
	changeToken = new EventEmitter();

	loginByEmail(data: IRequestLogin) {
		const url = `${URL_BACKEND}/auth/login`;
		return this._httpClient.post<IResponseLogin>(url, { ...data });
	}

	loginByMicrosoft(data: IRequestMicrosoft) {
		const url = `${URL_BACKEND}/auth/login/private`;
		return this._httpClient.post<IResponseLogin>(url, { ...data });
	}

	validateToken() {
		const url = `${URL_BACKEND}/auth/validate`;
		return this._httpClient.post<IResponseLogin>(url, null);
	}
}
