import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthLocalService } from '../services/auth/local/auth-local.service';
import { ModalErrorService } from '../services/modals/modal-error.service';

@Injectable({
	providedIn: 'root'
})
class PermissionsService {
	constructor(
		private _router: Router,
		private authLocalService: AuthLocalService,
		private modalErrorService: ModalErrorService
	) {}

	canActivate(): boolean {
		const isSessionActive = this.authLocalService.isSessionActive();
		if (this.authLocalService.isLogued() && isSessionActive) {
			const isNewPassword = this.authLocalService.isNewPassword();
			if (isNewPassword === true) {
				this._router.navigate(['/contrasena']);
				return false;
			}

			if (isNewPassword === null) {
				this._router.navigate(['/autenticar']);
				return false;
			}
			return true;
		} else {
			if (!isSessionActive) this.modalErrorService.openModalSesionExpired();
			this.authLocalService.signOut();
			this.authLocalService.singOutMSAL();
			this._router.navigate(['/autenticar']);
			return false;
		}
	}
}

export const AuthGuard: CanActivateFn = (): boolean => {
	return inject(PermissionsService).canActivate();
};
