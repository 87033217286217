import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-modal-loader',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './modal-loader.component.html',
	styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent {
	@Input() id = '';
	@Input() message = '';
}
