import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { decryptData } from 'src/app/core/utils/decrypt-data';
import { encryptData } from 'src/app/core/utils/encrypt-data';
import { AuthService } from '../api/auth.service';
@Injectable({ providedIn: 'root' })
export class AuthLocalService {
	constructor(
		private router: Router,
		private msalService: MsalService,
		private route: ActivatedRoute,
		private authService: AuthService
	) {}
	// manejo de la incriptación de los datos de usuario guardado en local
	handleDescryptDataUser() {
		const userJsonString = localStorage.getItem('dataUser');
		if (userJsonString) {
			return decryptData(userJsonString);
		}
		return null;
	}
	isLogued() {
		const user = localStorage.getItem('dataUser');
		return user ? true : false;
	}

	isNewPassword() {
		const user = this.getInfoUserLocal();
		return user ? user.passwordRenew : null;
	}

	getInfoUserLocal() {
		const dataUser = this.handleDescryptDataUser();
		if (dataUser) {
			return dataUser;
		}
		return null;
	}

	getToken() {
		const dataUser = this.handleDescryptDataUser();
		return dataUser ? dataUser.token : null;
	}

	getTokenPassword() {
		const token = localStorage.getItem('passwordToken');
		return token ? token : null;
	}

	getNameUser() {
		const user = this.getInfoUserLocal();
		return user ? user.username : null;
	}

	signOut(isRedirect = true) {
		localStorage.removeItem('dataUser');
		if (isRedirect) this.router.navigateByUrl('/autenticar');
	}

	singOutMSAL() {
		const currentAccount = this.msalService.instance.getActiveAccount();
		if (currentAccount) {
			localStorage.removeItem(`msal.${currentAccount.idTokenClaims.aud}.active-account`);
			localStorage.removeItem(`msal.${currentAccount.idTokenClaims.aud}.active-account-filters`);
			localStorage.removeItem(`msal.token.keys.${currentAccount.idTokenClaims.aud}`);
		}
	}

	//update passwordRenew
	updatePasswordRenew() {
		const dataUser = this.getInfoUserLocal();
		if (dataUser) {
			localStorage.setItem('dataUser', encryptData({ ...dataUser, passwordRenew: false }));
		}
	}

	getTokenExpired() {
		const dataUser = this.handleDescryptDataUser();
		if (dataUser) {
			return dataUser.tokenExpirationTime ? dataUser.tokenExpirationTime : null;
		}
		return null;
	}

	isSessionActive() {
		const expirationTime = this.getTokenExpired();
		if (expirationTime) {
			const currentDate = moment();
			const expirationDate = moment(expirationTime);
			return expirationDate.isAfter(currentDate);
		}
		return true;
	}

	updatePermissions() {
		this.authService.validateToken().subscribe({
			next: (res) => {
				if (res.data) {
					if (res.data.capabilityPermission.custome == null) res.data.capabilityPermission.custome = [];
					if (res.data.capabilityPermission.general == null) res.data.capabilityPermission.general = [];
					// get current dataUserLocal
					const currentDataUserLocal = this.getInfoUserLocal();
					if (currentDataUserLocal)
						localStorage.setItem(
							'dataUser',
							encryptData({
								...currentDataUserLocal,
								admin: res.data.admin, //update superadmin
								capabilityRoles: res.data.capabilityRoles,
								capabilityPermission: res.data.capabilityPermission,
								tokenExpirationTime: res.data.tokenExpirationTime,
								token: res.data.token
							})
						); //update local
				}
			}
		});
	}
}
