import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'fe-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	standalone: true,
	imports: [CommonModule, MatIconModule]
})
export class IconComponent implements OnInit, OnChanges {
	private _name: string;

	@Input() id = '';
	@Input() size: string | number;
	@Input() color = 'auto';
	@Input() iconMaterial = '';
	@Input() isError = false;
	@Input() isOutline = true;

	@Input() set name(value: string) {
		this._name = value;
		this.updateClasses();
	}

	get name(): string {
		return this._name;
	}

	public classes!: Array<string>;
	public backgroundClasses!: Array<string>;

	constructor() {
		this._name = 'face-id';
		this.size = '24';
	}

	ngOnInit() {
		this.updateClasses();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.color) this.updateClasses();
	}

	private updateClasses() {
		this.classes =
			this.iconMaterial == ''
				? ['bi', 'bi-' + this._name, 'fe-icon__color-' + this.color]
				: ['material-icons', 'fe-icon__color-' + this.color, this.isOutline ? 'material-icons-outlined' : ''];
	}
}
