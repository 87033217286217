<button
	[id]="id"
	[style.min-width]="text === 'Editar' ? minWidth : '100%'"
	[disabled]="disabled"
	[ngClass]="classes"
	(click)="onClicked()"
	[class.d-white]="disabledWhite"
>
	<fe-icon
		*ngIf="icon && iconPosition === 'left'"
		[id]="id + '-icon'"
		[name]="icon"
		[isOutline]="mode === 'secondary' ? false : true"
		[color]="iconColor"
		[size]="iconSize"
		[iconMaterial]="isIconMaterial ? icon : ''"
	></fe-icon>

	<img *ngIf="img" [src]="img" />

	<span *ngIf="text">
		{{ text }}
	</span>

	<fe-icon
		*ngIf="icon && iconPosition === 'right'"
		[id]="id + '-icon'"
		[name]="icon"
		[isOutline]="mode === 'secondary' ? false : true"
		[color]="iconColor"
		[size]="iconSize"
		[iconMaterial]="isIconMaterial ? icon : ''"
	></fe-icon>

	<div *ngIf="loading">
		<fe-animated-icon
			[id]="id + '-animated-icon'"
			size="25"
			trigger="loop"
			scale="85"
			name="xjovhxra"
			[colorPrimary]="colorAnimatedHexa"
		>
		</fe-animated-icon>
	</div>
</button>
