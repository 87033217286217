import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I_Message } from './modal-message.interface';
import { ButtonComponent } from '../button/button.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//pipes
import { RemoveSpacesPipe } from '../../../../core/pipes/remove-spaces.pipe';

@Component({
	selector: 'app-modal-message',
	standalone: true,
	imports: [CommonModule, MatIconModule, ButtonComponent, RemoveSpacesPipe],
	templateUrl: './modal-message.component.html',
	styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() iconMaterial = 'close';
	@Input() image = '';
	@Input() title = '';
	@Input() subTitle = '';
	@Input() typeMessage: I_Message = 'default';
	@Input() textButton = '';
	@Input() textButtonSecondary = '';
	@Input() isClose = false;
	@Input() time = 5000;
	@Input() isCloseAutomatic = false;
	isOverlayOnBack = false;
	@Output() clickButton = new EventEmitter();
	@Output() clickButtonSecondary = new EventEmitter();
	private boundOnClick: any;
	constructor(
		public activeModal: NgbActiveModal,
		@Inject(DOCUMENT) private document: Document,
		private location: Location,
		// ElementRef is used to access the DOM element of the component
		private el: ElementRef
	) {}
	ngOnDestroy(): void {
		if (this.isOverlayOnBack) {
			this.document.removeEventListener('click', this.boundOnClick);
		}
	}
	ngAfterViewInit(): void {
		// Increase a margin to the modal so that it doesn't look so close to the edges
		const element = this.el.nativeElement.parentElement;
		element.style.margin = '0 20px';
	}
	onEventOverlay(event: Event) {
		this.location.back();
	}
	ngOnInit(): void {
		if (this.isOverlayOnBack) {
			this.boundOnClick = this.onEventOverlay.bind(this);
		}
		if (this.isOverlayOnBack) {
			this.document.addEventListener('click', this.boundOnClick);
		}
		// element.classList.replace('modal-content', 'modal-responsive');
		// element.classList.add('modal-content');

		if (this.textButton == '' || this.isCloseAutomatic) {
			setTimeout(() => {
				this.closeModal();
			}, this.time);
		}
	}

	clickAction() {
		this.activeModal.close();
		this.clickButton.emit();
	}

	clickActionSecondary() {
		this.activeModal.close();
		this.clickButtonSecondary.emit();
	}

	closeModal() {
		this.activeModal.close();
		localStorage.removeItem('isSuccess');
	}
}
