import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { AuthLocalService } from './core/services/auth/local/auth-local.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'grupofe-axiomcloud-admin';
	constructor(
		private msalBroadcastService: MsalBroadcastService,
		private msalService: MsalService,
		private authLocalService: AuthLocalService
	) {}

	ngOnInit(): void {
		this.msalBroadcastService.msalSubject$
			.pipe(filter((msg: EventMessage) => msg.eventType === 'msal:logoutEnd'))
			.subscribe(() => {
				if (this.msalService.instance.getActiveAccount() === null) this.authLocalService.signOut();
			});
	}
}
