<section>
	<div class="close__modal" *ngIf="isClose">
		<img
			[id]="'modal-img-closeModal'"
			src="./assets/svg/close.svg"
			alt="x"
			width="20"
			height="20"
			(click)="closeModal()"
		/>
	</div>
	<div class="d-flex flex-column gap-4 justify-content-center align-items-center">
		<div [className]="'icon-modal icon-modal__' + typeMessage" *ngIf="image === ''">
			<mat-icon aria-hidden="false" [fontIcon]="iconMaterial"></mat-icon>
		</div>

		<img *ngIf="image !== ''" [src]="image" alt="img-modal" class="image-modal" />
		<div class="d-flex flex-column gap-2">
			<span class="title-modal" *ngIf="title !== ''">{{ title }}</span>
			<span class="subtitle-modal" *ngIf="subTitle !== ''" [innerHtml]="subTitle"></span>
		</div>
	</div>
	<div
		*ngIf="textButton !== ''"
		class="d-flex w-100 gap-4"
		[ngClass]="textButtonSecondary !== '' ? 'justify-content-between' : 'justify-content-center'"
	>
		<div class="actions" *ngIf="textButtonSecondary !== ''">
			<fe-button
				[id]="'modal-btn-' + (textButtonSecondary | removeSpaces) + '-clickActionSecondary'"
				mode="secondary"
				[text]="textButtonSecondary"
				type="full"
				(clicked)="clickActionSecondary()"
			></fe-button>
		</div>
		<div class="actions">
			<fe-button
				[id]="'modal-btn-' + (textButtonSecondary | removeSpaces) + '-clickAction'"
				[text]="textButton"
				type="full"
				(clicked)="clickAction()"
			></fe-button>
		</div>
	</div>
</section>
