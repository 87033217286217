export const environment = {
	production: true,
	msalConfig: {
		auth: {
			clientId: 'c2f90cfd-720f-4df3-a2f5-cbfca641f3c3',
			authority: 'https://login.microsoftonline.com/b4497caf-bd9d-499e-af13-df9dc9995dbf' // Grupo Fe
		}
	},
	appVersion: 'v0.0.1',
	urlAuth: 'https://auth.axiomcloud.pre.delfosti.site',
	urlAPI: 'https://api.axiomcloud.pre.delfosti.site',
	encryptSecretKey: 'acb646669432ffb027d2f0b982d7d8773a86594e390118d987f23f9d457dd4ca'
};
